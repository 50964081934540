import React from "react";
import { FormattedMessage } from "react-intl";

const NotFoundSection = () => (
    <div className="not-found-wrapper content center padding">
        <h2 className="title">
            <FormattedMessage
                id="not_found"
            />
        </h2>
    </div>
)

export default NotFoundSection;