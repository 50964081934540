import * as React from "react"
import SimpleLocalize from "../components/SimpleLocalize/SimpleLocalize";
import MainLayout from "../components/Layouts/MainLayout/MainLayout";
import NotFoundSection from "../components/Sections/NotFoundSection/NotFoundSection";

const NotFoundPage = (props) => {
  return (
    <>
      <SimpleLocalize {...props}>
        <MainLayout
            locationProps={props.location}
            navigate={props.navigate}
            currentLang={props.pageContext.language}
        >
            <NotFoundSection />
        </MainLayout>
      </SimpleLocalize>
      </>
  )
}

export default NotFoundPage;